<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

/**
 * Dashboard Component
 */
export default {
    page: {
    title: "Utilisateurs",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Utilisateurs",
      items: [
        {
          text: "Menu",
          href: "/",
        },
        {
          text: "Utilisateurs",
          active: true,
        },
        {
          text: "Un utilisateur",
          active: true,
        },
      ],
      user: {},
      transactions: [],
      id: "",
    };
  },
  methods: {
    getItem() {
      fetch(Helper.route('users/'+this.id), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        
        this.user = data
      })
    },
    getTransactions() {
      fetch(Helper.route('transactions/of-user/'+this.id), Helper.requestOptionsJSON())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        console.log("transactions : ",data);
        this.transactions = data
      })
    },
  },
  mounted() {
    this.id = this.$route.params.id
    this.getItem()
    this.getTransactions()
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    
    <div class="row" v-if="user.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">À propos de l'utilisateur</h4>
            <div class="row mt-4">
              <div class="col-md-3">
                <dl>
                  <dt>Nom de Famille</dt>
                  <dd>{{ user.last_name }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Prénom(s)</dt>
                  <dd>{{ user.first_name }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Sexe</dt>
                  <dd>{{ user.gender == true ? "Masculin" : "Féminin" }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Email</dt>
                  <dd>{{ user.email }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Téléphone</dt>
                  <dd>{{ user.phone }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Pays</dt>
                  <dd>{{ user.country ? user.country.name : "Non definie" }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Ville</dt>
                  <dd>{{ user.city }}</dd>
                </dl>
              </div>
            </div>

            <div class="row bg-white border py-4">
              <div class="col-lg-12">
                <div class="">
                  <div class="">
                    <h4 class="card-title">Listes des transactions</h4>
                    <p class="card-title-desc">
                      Le tableau ci-dessous présente la listes des <code>transactions</code>.
                    </p>

                    <div class="table-responsive">
                      <table class="table table align-middle mb-0">
                        <thead class="bg-light rounded">
                          <tr>
                            <th>#</th>
                            <th>iD</th>
                            <th>Nom</th>
                            <th>Date</th>
                            <th>Statut</th>
                            <th>Voir Detail</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(transaction, i) in transactions" :key="transaction.id">
                            <th scope="row">{{ i + 1 }}</th>
                            <td>{{ transaction.transaction_id }}</td>
                            <td>{{ transaction.name }}</td>
                            <td>{{ transaction.created_at }}</td>
                            <td>
                              <span v-if="transaction.status == 'EN ATTENTE' || transaction.status == 'CANCEL' || transaction.status == 'TRANSFERER'" class="badge badge-pill badge-soft-danger font-size-11">
                                  {{ transaction.status }}
                              </span>
                              <span v-if="transaction.status == 'EN COURS'" class="badge badge-pill badge-soft-orange font-size-11">
                                  {{ transaction.status }}
                              </span>
                              <span v-if="transaction.status == 'PROUVEE' || transaction.status == 'ECHOUE'" class="badge badge-pill badge-soft-danger font-size-11">
                                  {{ transaction.status }}
                              </span>
                              <span v-if="transaction.status == 'TERMEINE'" class="badge badge-pill badge-soft-success font-size-11">
                                  {{ transaction.status }}
                              </span>
                            </td>
                            <td>
                              <router-link class="rounded-pill btn-sm btn-primary px-3" :to="'/transactions/'+transaction.id">Voir detail</router-link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>


                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


  </Layout>
</template>
